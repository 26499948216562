import styled from 'styled-components'
import Arrow from '../images/arrow-background.svg'
import ArrowAlt from '../images/arrow-background-alt.svg'
import { ImageWrapper } from '../components/FavouritesList/style.js'
import { device } from './breakpoints'

export const ContentStyles = styled.div`
  > *:nth-child(1) {
    margin-top: 0 !important;
  }
  position: relative;
  z-index: 2;
  background-color: ${props => props.theme.backgroundColor};
  padding-bottom: ${props => props.theme.backgroundColor && '40px'};
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > p {
    padding: 0 8px;
    color: ${props => props.theme.headerTextColor};
    text-transform: ${props => props.theme.textTransform};

    @media (min-width: 768px) {
      padding: 0;
    }
  }

  > hr {
    max-width: 780px;
    margin: 30px auto;
  }
  > h2 {
    font-size: ${props => (props.isStories ? '20px' : '32px')};
    margin: 0 auto;
    line-height: 100%;
    margin-top: 30px;
    margin-bottom: 30px;

    @media (min-width: 768px) {
      font-size: ${props => (props.isStories ? '28px' : '42px')};
      margin-bottom: 45px;
      margin-top: 45px;

      max-width: 780px;
    }
  }

  > p.intro {
    font-family: Freight Big;
    margin: 0 auto;
    color: ${props =>
      props.theme.mainTextColor ? props.theme.mainTextColor : '#212c1f'};
    font-size: 24px;
    line-height: 117%;
    text-transform: none;

    margin-bottom: 30px;

    @media (min-width: 768px) {
      font-size: 32px;
      margin-bottom: 45px;
      line-height: 125%;
      max-width: 780px;
    }
  }

  > p:first-of-type {
    ${props =>
      !props.simple &&
      `
font-family: Freight Big Black;
		margin: 0 auto;
		color: #212c1f;
		font-size: 24px;
		line-height: 117%;
		text-transform: none;
		font-weight: bold;
		margin-bottom: 30px;

		`} @media (min-width: 768px) {
      ${props =>
        !props.simple &&
        `
			font-size: 32px;
			margin-bottom: 45px;
			line-height: 125%;
			max-width: 780px;

			`};
    }

    ${props =>
      props.theme.mainTextColor &&
      `
			color: ${props.theme.mainTextColor}`};
  }

  > p,
  p:only-child {
    font-size: 16px;
    font-family: 'Maison Neue', sans-serif;
    line-height: 150%;
    margin: 0 auto;
    &.has-text-align-center {
      text-align: center;
    }
    font-weight: 300;
    color: ${props =>
      props.theme.mainTextColor ? props.theme.mainTextColor : '#545c52'};
    margin-bottom: 30px;

    @media (min-width: 768px) {
      font-size: 20px;
      max-width: ${props =>
        props.operators ? '100% !important' : '780px !important'};
    }

    a {
      font-weight: 500;
      color: ${props =>
        props.theme.mainTextColor ? props.theme.mainTextColor : '#212c1f'};
      position: relative;
      /* text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff; */

      background-image: ${props => `linear-gradient(
				to top,
				rgba(165, 83, 49, 0),
				rgba(165, 83, 49, 0) 2px,
				${props.theme.headerTextColor || '#a55331'} 2px,
				${props.theme.headerTextColor || '#a55331'} 3px,
				rgba(165, 83, 49, 0) 3px
			)`};

      :hover {
        color: ${props => props.theme.headerTextColor || '#a55331'};
      }
    }

    > b,
    > strong {
      font-weight: 500;
      color: #212c1f;
    }
  }

  > b,
  > strong {
    font-weight: 500;
    color: #212c1f;
  }

  > h3,
  > h4,
  > h5 {
    margin: 0 auto;
    line-height: 150%;

    @media (min-width: 768px) {
      max-width: 780px;
    }
  }

  > h3 {
    font-size: ${props => (props.isStories ? '20px' : '24px')};
    margin-top: 35px;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: ${props => (props.isStories ? '28px' : '32px')};
      margin-top: ${props => (props.itinerary ? '0px' : '60px')};

      margin-bottom: 30px;
    }
  }

  > h4 {
    font-size: 20px;
    margin-top: 35px;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 28px;
      margin-top: 60px;

      margin-bottom: 30px;
    }
  }

  > h5 {
    font-size: 18px;
    margin-top: 35px;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 22px;
      margin-top: 60px;
      margin-bottom: 30px;
    }
  }
  .wp-block-button {
    position: relative;
    max-width: 780px !important;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 102px;
    cursor: pointer;
    a {
      display: inline-flex;
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      color: #212c1f;
      padding-left: 50px;
      line-height: 26px;
      @media (min-width: 1024px) {
        padding-left: 55px;
        font-size: 18px;
      }
    }
    :hover {
      a {
        color: #a55331;
      }
    }
    :before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 34px;
      width: 34px;
      background-image: url(${Arrow});
      background-size: 100%;
      background-repeat: no-repeat;
      z-index: 2;
      @media (min-width: 1024px) {
        height: 38px;
        width: 38px;
      }
    }
  }

  div.image-text {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 45px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 40px;
    }

    @media (min-width: 1024px) {
      margin-top: 100px;
      margin-bottom: 90px;
    }

    &.festivals {
      margin-top: 0;
      margin-bottom: 0px;

      @media (min-width: 480px) {
        margin-bottom: 0px;
      }

      @media (min-width: 768px) {
        margin-bottom: 25px;
      }

      @media (min-width: 1280px) {
        margin-bottom: 40px;
      }
    }
  }

  div.image-text__col--img {
    height: 100%;
    width: 100%;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      display: block;
      margin: 0;
      object-fit: cover;
    }

    @media (min-width: 768px) {
      &.festivals {
        position: relative;

        img {
          position: absolute;
        }
      }
    }
  }

  div.image-text__col--text {
    padding: 40px 38px;
    background: ${props => props.theme.otherColor || '#212c1f'};
    * {
      color: white;
    }
    @media (min-width: 768px) {
      padding: 40px 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.festivals {
        padding: 60px;
      }
    }

    @media (min-width: 1024px) {
      padding: 130px 100px;

      &.festivals {
        padding: 100px;
      }
    }

    h4 {
      font-size: 20px;
      color: #fbf9f6;
      letter-spacing: -0.18px;
      line-height: 100%;
      margin-top: 0;
      margin-left: 0;

      @media (min-width: 1024px) {
        font-size: 28px;
        margin-bottom: 30px;
      }
    }

    p {
      font-weight: 300;
      font-size: 16px;
      color: #fbf9f6;
      letter-spacing: 0;
      line-height: 150%;
      margin: 0;
      margin-top: 10px;

      @media (min-width: 1024px) {
        font-size: 18px;
        margin-top: 20px;
      }

      a {
        font-weight: 500;
        color: #fbf9f6;
        position: relative;
        text-shadow: none;

        ::after {
          content: '';
          position: absolute;
          bottom: 2px;
          height: 1px;
          width: 100%;
          left: 0;
          z-index: 1;
          opacity: 0;
          transition: 60ms;
          background-color: #ffffff;
        }
        :hover {
          ::after {
            opacity: 1;
          }
        }
      }
    }
  }

  figure.wp-block-image.alignwide {
    max-width: 100%;

    img {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      display: block;
    }
  }

  figure.aligncenter {
    text-align: center;
  }

  figure.wp-block-image {
    max-width: 780px;
    margin: 0 10px;
    margin-bottom: 25px;

    @media (min-width: 768px) {
      margin: 0 auto;
      margin-bottom: 50px;
      margin-top: 76px;
    }

    img {
      margin: 0 auto;
      height: auto;
      vertical-align: middle;
    }

    figcaption {
      max-width: 780px;
      margin: 0 auto;
      color: ${props => props.theme.mainTextColor || '#545C52'};
      border-left: ${props =>
        props.theme.headerTextColor ? null : '1px solid #e0d6c8'};
      border-bottom: ${props => `1px solid ${props.theme.headerTextColor}`};

      font-size: 12px;
      padding: 20px 20px 20px 20px;
      padding-left: ${props => (props.theme.headerTextColor ? 0 : null)};

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
  }

  div.image-tiles {
    margin: 30px auto;
    background-color: ${props => props.theme.backgroundColor};
    /* padding: 20px; */
    padding-bottom: 38px;
    max-width: 1200px;

    @media (min-width: 768px) {
      /* padding: 40px; */
      margin: 50px auto;
    }

    @media (min-width: 1200px) {
      /* padding: 120px; */
      margin: 70px auto;
    }

    div.image-tiles__images {
      display: grid;
      grid-template-columns: 1fr 1fr;

      grid-gap: 15px;
      margin-bottom: 35px;

      @media (min-width: 800px) {
        grid-gap: 30px;
      }
      @media (min-width: 1024px) {
        grid-gap: 60px;
      }

      img {
        margin: 0;
        width: 100%;
        object-fit: cover;
        max-height: 580px;
      }
    }

    div.image-tiles__copy {
      display: grid;
      grid-template-columns: 1fr;

      @media (min-width: 800px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
      }

      @media (min-width: 1024px) {
        grid-gap: 60px;
      }
      h3.image-tiles__heading {
        color: ${props => props.theme.headerTextColor || '#212c1f'};
        text-transform: none;
        font-size: 32px;
        line-height: 100%;
        margin-bottom: 17px;

        @media (min-width: 1024px) {
          font-size: 48px;
        }
      }

      div.image-tiles__description {
        p {
          font-size: 16px;
          color: ${props =>
            props.theme.mainTextColor ? props.theme.mainTextColor : '#212c1f'};
          line-height: 150%;
          margin-bottom: 14px;
          @media (min-width: 1024px) {
            font-size: 20px;
            margin-bottom: 32px;
          }
        }

        a {
          color: ${props =>
            props.theme.headerTextColor
              ? props.theme.headerTextColor
              : '#212c1f'};
          font-size: 12px;
          line-height: 200%;
          font-weight: 500;
          padding: 4px 0;
          position: relative;
          ::after {
            background-color: ${props =>
              props.theme.headerTextColor
                ? props.theme.headerTextColor
                : '#212c1f'};
            position: absolute;
            content: '';
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 1px;
            opacity: 0;
            transition: 100ms;
          }
          @media (min-width: 1024px) {
            font-size: 14px;
          }
          :hover {
            ::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  blockquote.wp-block-quote {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 40px auto 40px auto;
    border-top: ${props =>
      props.theme.headerTextColor
        ? `1px solid ${props.theme.headerTextColor}`
        : '1px solid black'};

    @media (min-width: 768px) {
      margin-bottom: 84px;
      margin-top: 100px;
    }

    p {
      font-family: Freight Big;
      font-size: 32px;
      color: ${props =>
        props.theme.mainTextColor ? props.theme.mainTextColor : '#a55331'};
      text-align: center;
      line-height: 112.5%;
      text-transform: none;
      padding: 0 20px;
      margin-bottom: 34px;
      padding-top: 45px;
      max-width: 100% !important;
      @media (min-width: 768px) {
        padding: 0 100px;
        padding-top: 90px;
        margin-bottom: 81px;
        font-size: 42px;
      }
    }

    cite {
      font-weight: 500;
      color: ${props =>
        props.theme.headerTextColor ? props.theme.headerTextColor : '#212c1f'};
      text-align: center;
      font-size: 14px;
      text-transform: none;
      font-style: normal;
      position: relative;
      display: inline;
      padding: 0 20px;
      max-width: 250px;

      @media (min-width: 768px) {
        padding: 0 25px;
        max-width: 100%;
      }

      ::before,
      ::after {
        content: '';
        position: absolute;
        background: ${props =>
          props.theme.headerTextColor ? props.theme.headerTextColor : 'black'};
        height: 1px;
        top: 50%;
        width: 1000%;
        display: block;
      }

      ::before {
        right: 100%;
      }

      ::after {
        left: 100%;
      }
    }
  }

  iframe {
    width: 100% !important;

    &.rezdy {
      max-width: 780px !important;
      margin: 0px auto 30px !important;
      display: block;
    }
  }
  figure.is-type-video {
    position: relative;
    padding-top: 56.25%;
    margin: 0;
    margin-top: 15px;
    margin-bottom: 40px;

    @media (min-width: 768px) {
      margin-top: 90px;
      margin-bottom: 90px;
      /* max-width: 1200px; */
    }

    > div,
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }

  > ul {
    padding: 0 4px;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 20px;

    @media (min-width: 768px) {
      margin: 0 auto;
      max-width: 780px;
      margin-bottom: 50px;
      margin-top: 50px;
    }

    li {
      list-style-type: none;
      padding-left: 30px;
      font-size: 16px;
      color: ${props =>
        props.theme.mainTextColor ? props.theme.mainTextColor : '#212c1f'};
      position: relative;
      margin-bottom: 20px;

      a {
        font-family: Maison Neue;
        font-weight: 500;
        font-size: 15px;
        color: #545c52;
        letter-spacing: 0;
        line-height: 20px;

        border-bottom: 1px solid #545c52;
        display: inline-block;

        :hover {
          color: #a55331;
          border-bottom: 1px solid #fbf9f6;
        }
        @media (min-width: 768px) {
          font-size: 20px;
        }
      }

      ::before {
        content: '';
        height: 6px;
        width: 6px;
        background: ${props =>
          props.theme.mainTextColor ? props.theme.mainTextColor : '#a55331'};
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        left: 3px;
      }

      @media (min-width: 768px) {
        font-size: 20px;
        margin-bottom: 26px;

        ::before {
          top: 11px;
        }
      }
    }
  }

  > ol {
    padding: 0 4px;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 20px;

    @media (min-width: 768px) {
      margin: 0 auto;
      max-width: 780px;
      margin-bottom: 50px;
      margin-top: 50px;
    }

    li {
      list-style-type: none;
      padding-left: 30px;
      font-size: 16px;
      position: relative;
      color: ${props =>
        props.theme.mainTextColor ? props.theme.mainTextColor : '#212c1f'};
      margin-bottom: 20px;
      counter-increment: step-counter;

      ::before {
        content: counter(step-counter);
        font-size: 110%;
        color: ${props =>
          props.theme.mainTextColor ? props.theme.mainTextColor : '#a55331'};
        font-weight: 400;
        display: block;
        position: absolute;
        top: 0px;
        left: 3px;
      }

      @media (min-width: 768px) {
        font-size: 20px;
        margin-bottom: 26px;
      }
    }
  }

  .rs-table {
    margin: 0 auto;
    max-width: 1200px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #a55331;
    }

    &::-webkit-scrollbar-track {
      background: rgb(84, 92, 82);
      box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.5);
    }
  }

  .wp-block-table {
    min-width: 768px;
    margin: 0;
    td {
      vertical-align: top;
      a {
        color: ${props =>
          props.theme.mainTextColor ? props.theme.mainTextColor : '#212c1f'};
        font-weight: 500;
        position: relative;
        ::after {
          content: '';
          position: absolute;
          bottom: 2px;
          height: 1px;
          width: 100%;
          left: 0;
          z-index: 1;
          opacity: 0;
          transition: 60ms;
          background-color: ${props =>
            props.theme.headerTextColor
              ? props.theme.headerTextColor
              : '#a55331'};
        }

        :hover {
          color: ${props =>
            props.theme.headerTextColor
              ? props.theme.headerTextColor
              : '#a55331'};
          ::after {
            opacity: 1;
          }
        }
      }
      color: ${props =>
        props.theme.mainTextColor ? props.theme.mainTextColor : '#212c1f'};
      padding: 20px 14px 20px 14px;
      border-bottom: ${props =>
        props.theme.headerTextColor
          ? `1px solid ${props.theme.headerTextColor}`
          : '1px solid #'};
    }

    &.is-style-stripes {
      tr:nth-child(odd) {
        background: none;
      }
      tr:first-child {
        background: #212c1f;

        td,
        td * {
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }

  .acf-block--cta {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    margin: 30px 0;
    .cta-img {
      height: auto;
      max-height: 380px;
      overflow: hidden;
      img {
        margin: 0 !important;
        object-fit: cover;
        height: 100%;
        width: 100%;
        object-position: 50% 50%;
      }
    }
    @media (${device.desktop}) {
      grid-template-columns: 1fr 1fr;

      margin: 76px 0;

      .cta-img {
        max-height: 100%;
      }
    }
    .acf-block__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #a45331;
      padding: 24px;
      @media (${device.desktop}) {
        padding: 90px;
      }
      h3 {
        color: white;

        @media (${device.desktop}) {
          font-size: 28px;
          margin-bottom: 30px;
        }
      }

      p {
        @media (${device.desktop}) {
          font-size: 18px;
          margin-bottom: 30px;
        }
      }

      p:empty {
        display: none;
      }
      color: white;

      a {
        background: none;
        outline: none;
        border: none;
        display: flex;
        color: white;
        align-items: center;
        font-weight: 500;
        padding-left: 55px;
        min-height: 40px;
        cursor: pointer;

        &:after {
          content: '';
          display: block;
          position: absolute;
          transform: translateX(-55px);
          height: 40px;
          width: 40px;
          background-image: url(${ArrowAlt});
          background-size: 100%;
          background-repeat: no-repeat;
          z-index: 2;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`

export const ComponentContainer = styled.div`
  max-width: ${props => (props.theme.backgroundColor ? null : '1440px')};
  margin: 0 auto;
  padding: ${props => props.theme.globalPaddingMobile};
  background-color: ${props => props.theme.backgroundColor};
  @media (min-width: 768px) {
    padding: 0 30px;
  }

  @media (min-width: 1280px) {
    padding: ${props => props.theme.globalPaddingDesktop};
  }
`

export const OverflowContainer = styled.div`
  overflow: hidden;

  > p {
    margin-top: 10px;

    @media (min-width: 1024px) {
      margin-top: 40px;
      font-size: 18px;
    }
  }
`

export const MoreHeader = styled.h2`
  text-align: center;
  font-family: Freight Big;
  text-transform: none;
  font-size: 32px;
  z-index: -1000;
  margin-top: 34px;
  margin-bottom: 34px;

  @media (min-width: 768px) {
    font-size: 46px;
    margin-top: 46px;
    margin-bottom: 46px;
  }
`

export const ThreeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 18px;

  @media (min-width: 480px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 55px;
  }
`

export const TwoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  margin-bottom: 10px;
  border-bottom: ${props => (props.category ? 'none' : '1px solid black')};
  padding-bottom: 20px;

  @media (min-width: 480px) {
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 768px) {
    padding-bottom: 0px;
  }
  @media (min-width: 1024px) {
    grid-gap: 55px;
    margin-bottom: 20px;
  }

  /*
::after {
		content: "";
		position: absolute;
		margin: 0;
		background: black;
		height: 1px;
		opacity: 1;
		width: 100%;
		display: block;

	} */
`

export const SubText = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  position: relative;
  padding-bottom: 6px;
  padding-left: 8px;
  margin-bottom: ${props => (props.removeMargin ? '0' : '30px')};

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    padding-left: 0;

    padding-bottom: 30px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 50px;
    grid-gap: 55px;
    margin-bottom: 50px;
  }

  h4 {
    font-family: 'Freight Big';
    font-size: 24px;
    text-transform: none;

    @media (min-width: 768px) {
      width: 230px;
      font-size: 32px;
      margin: 0;
    }

    @media (min-width: 1024px) {
      width: 100%;
    }
  }

  p {
    font-size: 16px;
    line-height: 150%;
    grid-column: span 2;
    padding-right: 50px;

    @media (min-width: 768px) {
      font-size: 18px;
      margin: 0;
    }

    @media (min-width: 1024px) {
      padding-right: 0px;

      max-width: 645px;
      font-size: 20px;
    }
  }

  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    margin: 0;
    background: black;
    height: 1px;
    width: 100%;
    display: block;

    @media (min-width: 768px) {
      display: block;
    }
  }
`

export const ItineraryStyles = styled.div`
  p {
    font-size: 16px;
    line-height: 150%;

    font-weight: 300;
    color: #545c52;

    margin-bottom: 25px;

    @media (min-width: 768px) {
      font-size: 18px;
    }

    a {
      font-weight: 500;
      color: #212c1f;

      :hover {
        color: #a55331;
      }
    }
  }

  figure.wp-block-image.alignwide {
    max-width: 100%;

    img {
      margin: 0 auto;
      display: block;
    }
  }

  figure.wp-block-image {
    margin: 0;

    @media (min-width: 768px) {
      /* padding: 0 50px; */
      flex-basis: 50%;
      /* padding: 0 50px 0 30px; */
    }

    img {
      margin: 0;
      vertical-align: middle;
    }

    figcaption {
      max-width: 780px;
      margin: 0 auto;
      border-left: 1px solid #e0d6c8;
      font-size: 12px;
      padding: 20px 20px 20px 20px;

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
  }
`

export const SidebarPageContentStyles = styled(ContentStyles)`
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > p,
  > ul,
  > ol {
    @media (min-width: 1024px) {
      padding-right: 30px;
    }
  }

  .acf-block--cta {
    @media (${device.desktop}) {
      grid-template-columns: 1fr;
      .cta-img {
        max-height: 380px;
      }
    }

    .acf-block__text {
      @media (${device.desktop}) {
        padding: 56px 64px 64px 64px;
      }
    }
  }

  blockquote {
    p {
      @media (${device.desktop}) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
`

export const SidebarPageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 1024px) {
    grid-template-columns: 8fr 4fr;
    gap: 50px;

    > div:nth-child(2) {
      border-left: 1px solid #b9bab4;
      padding-left: 50px;
    }
  }
`
