import styled from 'styled-components'
import { device } from '../../utils/breakpoints'
import FancyLink from '../../utils/FancyLink'

export const TopLine = styled.hr`
  display: none;
  background: #b9bab4;
  margin: 0;
  @media (${device.desktop}) {
    display: block;
  }
`

export const ListGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (${device.tablet}) {
    grid-gap: 30px;
    padding-bottom: 30px;
  }

  @media (${device.desktop}) {
    grid-gap: 57px;
  }
`

export const ItemWrapper = styled.div`
  padding-bottom: 30px;
  position: relative;

  @media (${device.tablet}) {
    padding: 30px 0;

    border-bottom: 1px solid #b9bab4;

    :last-child {
      border-bottom: none;
    }
  }

  @media (${device.desktop}) {
    padding: 57px 0;
  }

  @media print {
    padding: 0 !important;
    margin: 15px 0;
    display: block;
    border-bottom: 3px solid #212c1f;
  }
`

export const Shifters = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  @media print {
    display: none;
  }
`

export const ShiftButton = styled.button`
  height: 40px;
  width: 40px;
  background: #a55331;
  border: 1px solid #fbf9f6;
  transition: 100ms;
  cursor: pointer;
  padding: 0;

  :hover {
    background: #8d472a;
  }

  @media (${device.tablet}) {
    height: 30px;
    width: 30px;
  }

  @media (${device.desktop}) {
    height: 40px;
    width: 40px;
  }

  svg {
    path {
      fill: #ffffff;
    }
  }

  ${props =>
    props.down &&
    `
  top:39px;
  svg {
    transform:scale(-1);
  }


  }
  `};
`

export const ImageWrapper = styled.div`
  padding-top: 74%;
  align-self: flex-start;
  margin-bottom: 25px;
  position: relative;

  @media (${device.tablet}) {
    margin-bottom: 0;
  }

  > div {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media print {
    padding-top: 35%;
    break-inside: avoid;
  }
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (${device.tablet}) {
    grid-template-columns: 3fr 6fr 4fr;
    grid-gap: 30px;
  }

  @media (${device.desktop}) {
    grid-gap: 54px;
  }

  @media print {
    display: block;
  }
`

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    display: inline-block;
    margin-bottom: 16px;

    @media (${device.tablet}) {
      margin-bottom: 20px;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
`

export const DetailsWrapper = styled.div`
  border-top: 1px solid #b9bab4;
  margin-top: 30px;
  padding-top: 25px;

  @media (${device.tablet}) {
    border-top: none;
    padding-top: 0;
    margin-top: 0;

    border-left: 1px solid #b9bab4;
    padding-left: 20px;
  }

  @media (${device.desktop}) {
    padding-left: 80px;
    padding-right: 30px;
  }

  @media print {
    margin: 0;
    padding: 0;
    margin-top: 15px;
    padding-top: 15px;
    border: none;
  }
`

export const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 20px;

  @media (${device.desktop}) {
    font-size: 22px;
    margin-bottom: 24px;
  }
  transition: 100ms;

  :hover {
    a {
      color: #a55331;
    }
  }
`

export const Description = styled.p`
  margin-bottom: 20px;
  font-size: 14px;

  @media (${device.desktop}) {
    margin-bottom: 24px;
    font-size: 16px;
  }

  @media print {
    display: none;
  }
`

export const PrintDescription = styled.p`
  display: none;
  margin-bottom: 20px;
  font-size: 14px;

  @media print {
    display: block;
  }

  span {
    font-weight: 400;
  }

  a {
    font-weight: 500;
    position: relative;
    color: #212c1f;

    ::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #212c1f;
    }
  }
`

export const TextLink = styled(FancyLink)`
  display: inline;
  font-size: 14px;
  font-weight: 500;

  color: #212c1f;
  position: relative;

  background-image: linear-gradient(to right, #a55331, #a55331);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;

  @media (${device.desktop}) {
    font-size: 16px;
  }

  :hover {
    color: #a55331;
  }
`
